import React, { useEffect, useState } from 'react'
import './service-full-data.scss'
import { getTimeFromSecond } from '../../../assets/javascript/formate-functions'
import Spinner from '../../../components/common/spinners/SpinWithMessage'
import { FaRegDotCircle } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { LuIndianRupee } from "react-icons/lu";
import { FaCheckCircle } from 'react-icons/fa'
import { IoMdCloseCircle } from "react-icons/io";
import { serviceAxios } from '../../../config/axios'

const ServiceFullData = ({ data, admin }) => {
    const [totalReceivedAmt, setTotalReceivedAmt] = useState(0)
    const [descriptions, setDescriptions] = useState([])
    const [loading, setLoading] = useState('')


    const handleBillPdf = () => {
        window.open(
            `https://controlnex.alliancedev.in/payment/pdf-generator?srl_number=${data?.service_srl_number}&cid=${data?.cid}&date=${data.date}&type=Bill`,
            '_blank',
            'width=800,height=600,menubar=no,toolbar=no,location=no,status=no'
        );
    }

    const handleReceiptPdf = () => {
        window.open(
            `https://controlnex.alliancedev.in/payment/pdf-generator?srl_number=${data?.service_srl_number}&cid=${data?.cid}&date=${data.date}&type=Receipt`,
            '_blank',
            'width=800,height=600,menubar=no,toolbar=no,location=no,status=no'
        );
    }

    useEffect(() => {
        setLoading('fetch')
        let total = 0
        data?.payment_history?.map((pay) => {
            if (pay?.confirm) {
                total += pay.total_amount
            }
            return pay
        })
        setTotalReceivedAmt(total)
        if (data?.reg_type && !descriptions?.[0]) {
            serviceAxios.get(`/registered/${data?.reg_type}?reg_no=${data?.reg_no}`).then((response) => {
                setDescriptions(response?.data?.work_description || [])
                setLoading('')
            }).catch(() => setLoading(''))
        } else {
            setLoading('')
        }
        // eslint-disable-next-line
    }, [data])

    return (
        <div className="service-full-data-div">
            {/* PDF Download */}
            <div className="pdf-download-div">
                <div className={'left'}>
                </div>
                <div className={'right'}>
                    <button onClick={handleBillPdf}>Bill</button>
                    {totalReceivedAmt > 0 && <button onClick={handleReceiptPdf}>Receipt</button>}
                </div>
            </div>
            {/* About service */}
            <div className="section-div about-work-div">
                <div className="section-title-div">
                    <FaRegDotCircle />
                    <h4>About service</h4>
                </div>
                <div className="content-div">
                    {/*  */}
                    <div className="sub-section-div">
                        <div className='texts'>
                            <div className="line-div">
                                <p>Date</p>
                                <p>{new Date(data?.date).toDateString()}</p>
                            </div>
                            <div className="line-div">
                                <p>Customer ID</p>
                                <p>{data?.cid}</p>
                            </div>
                            <div className="line-div">
                                <p>Customer Name</p>
                                <p>{data?.name_of_customer}</p>
                            </div>
                            <div className="line-div">
                                <p>Current Status</p>
                                <span className={`text-badge ${data?.purifier_customer_status}-text`}>{data?.purifier_customer_status}</span>
                            </div>
                            <div className="line-div">
                                <p>Reg no</p>
                                <p>{data?.reg_no}</p>
                            </div>
                            <div className="line-div">
                                <p>Reg type</p>
                                <p>{data?.reg_type}</p>
                            </div>
                            <div className="line-div">
                                <p>{data?.reg_type === 'complaint' ? 'Complaint index' : 'Service index'}</p>
                                <p>{data?.reg_type === 'complaint' ? data?.complaint_index : data?.service_index || 'Nill'}</p>
                            </div>
                            <div className="line-div">
                                <p>Package ID</p>
                                <p>{data?.package_id || 'Nill'}</p>
                            </div>
                            <div className="line-div">
                                <p>SSP Card number</p>
                                <p>{data?.current_ssp_card_no || "Nill"}</p>
                            </div>
                        </div>
                    </div>
                    {/*  */}
                    <div className="sub-section-div">
                        <div className='texts'>
                            <div className="line-div">
                                <p>Srl number</p>
                                <p>{data?.service_srl_number}</p>
                            </div>
                            <div className="line-div">
                                <p>Technician</p>
                                <p>{data?.technician}</p>
                            </div>
                            <div className="line-div">
                                <p>In time</p>
                                <p>{new Date(data?.in_time).toLocaleTimeString()}</p>
                            </div>
                            <div className="line-div">
                                <p>Out time</p>
                                <p>{new Date(data?.out_time).toLocaleTimeString()}</p>
                            </div>
                            <div className="line-div">
                                <p>Duration</p>
                                <p>{getTimeFromSecond(data?.duration)}</p>
                            </div>
                            <div className="line-div">
                                <p>Work</p>
                                <p>{data?.work}</p>
                            </div>
                            <div className="line-div">
                                <p>Work method</p>
                                <p>{data?.work_method}</p>
                            </div>
                            <div className="line-div">
                                <p>Send zone</p>
                                <p>{data?.zone}</p>
                            </div>
                            <div className="line-div">
                                <p>New status</p>
                                {data?.service_data?.new_customer_status ?
                                    <span className={`text-badge ${data?.service_data?.new_customer_status}-text`}>{data?.service_data?.new_customer_status}</span>
                                    : '-'}
                            </div>
                        </div>
                    </div>
                    {/*  */}
                    <div className="sub-section-div">
                        <div className='texts'>
                            <div className="line-div">
                                <p>Work status</p>
                                {data?.inspection_report?.action === 'Completed'
                                    ? <span className={`text-badge green-text`}>{data?.inspection_report?.action}</span>
                                    : <span className={`text-badge red-text`}>{data?.inspection_report?.action}</span>
                                }
                            </div>
                            <div className="line-div">
                                <p>Carbon filter change</p>
                                {data?.service_data?.carbon_filter_changed
                                    ? <span className={`text-badge green-text`}>Yes</span>
                                    : <span className={`text-badge`}>No</span>
                                }
                            </div>
                            <div className="line-div">
                                <p>Package renewal</p>
                                {data?.service_data?.package_renewal
                                    ? <span className={`text-badge green-text`}>Yes</span>
                                    : <span className={`text-badge`}>No</span>
                                }
                            </div>
                            <div className="line-div">
                                <p>Periodical service</p>
                                {data?.service_data?.periodical_service
                                    ? <span className={`text-badge green-text`}>Yes</span>
                                    : <span className={`text-badge`}>No</span>
                                }
                            </div>
                            <div className="line-div">
                                <p>Repeat work</p>
                                {data?.service_data?.repeat_tech
                                    ? <span className={`text-badge red-text`}>Yes</span>
                                    : <span className={`text-badge `}>No</span>
                                }
                            </div>
                            <div className="line-div">
                                <p>Payment status</p>
                                {!data?.balance_amount
                                    ? <span className={`text-badge green-text`}>Paid</span>
                                    : <span className={`text-badge red-text`}>Pending</span>
                                }
                            </div>
                            <div className="line-div">
                                <p>Cust. Verify</p>
                                {data?.signature?.url
                                    ? <span className={`text-badge green-text`}>Yes</span>
                                    : <span className={`text-badge red-text`}>No</span>
                                }
                            </div>
                            <div className="line-div">
                                <p>Above 24 kms</p>
                                {data?.service_data?.above_24
                                    ? <span className={`text-badge green-text`}>Yes</span>
                                    : <span className={`text-badge `}>No</span>
                                }
                            </div>
                            <div className="line-div">
                                <p>Cabin change</p>
                                {data?.service_data?.cabin_change
                                    ? <span className={`text-badge green-text`}>Yes</span>
                                    : <span className={`text-badge `}>No</span>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {/* Description */}
            <div className="section-div description-div">
                <div className="section-title-div">
                    <FaRegDotCircle />
                    <h4>Descriptions</h4>
                </div>
                <div className="table-content-div">
                    {/*  */}
                    <table className='table-div'>
                        <tbody>
                            {descriptions?.[0]
                                ? <>
                                    {descriptions.map((desc) => <tr>
                                        <td>{new Date(desc?.date).toDateString()}</td>
                                        <td>{desc?.by}</td>
                                        <td>{desc?.description}</td>
                                    </tr>)}
                                </>
                                : <tr><td>{loading === 'fetch' ? 'Fetch data...' : 'Descriptions not available'}</td></tr>}

                        </tbody>
                    </table>
                </div>
            </div>

            {/* Assessments report */}
            <div className="section-div assessments-div">
                <div className="section-title-div">
                    <FaRegDotCircle />
                    <h4>Assessments report</h4>
                </div>
                <div className="content-div">
                    {/* Current condition */}
                    <div className="sub-section-div">
                        <h5>Current condition</h5>
                        <div className='texts'>
                            <div className="line-div">
                                <p>Working status</p>
                                {data.before_checking_data?.purifier_working_status
                                    ? <span className={`text-badge green-text`}>OK</span>
                                    : <span className={`text-badge red-text`}>NOT</span>}
                            </div>
                            {data.before_checking_data?.tds_status && <div className="line-div">
                                <p>TDS</p>
                                <p>{data.before_checking_data?.input_tds} / {data.before_checking_data?.output_tds}</p>
                                <span className={`text-badge ${data.before_checking_data?.tds_status}-text`}>{data.before_checking_data?.tds_status}</span>
                            </div>}
                            {data?.before_checking_data?.filtered_water_flow_status && <div className="line-div">
                                <p>Filter water flow</p>
                                <p>{data.before_checking_data?.filtered_water_flow_ltrs} / {data.before_checking_data?.pump}</p>
                                <span className={`text-badge ${data?.before_checking_data?.filtered_water_flow_status}-text`}>{data?.before_checking_data?.filtered_water_flow_status}</span>
                            </div>}
                            {data?.before_checking_data?.reject_water_flow_status && <div className="line-div">
                                <p>Reject water flow</p>
                                <p>{data.before_checking_data?.reject_water_flow_ltrs} / FR {data.before_checking_data?.fr}</p>
                                <span className={`text-badge ${data?.before_checking_data?.reject_water_flow_status}-text`}>{data?.before_checking_data?.reject_water_flow_status}</span>
                            </div>}
                            {data?.before_checking_data?.ph_status && <div className="line-div">
                                <p>PH</p>
                                <p>{data.before_checking_data?.ph}</p>
                                <span className={`text-badge ${data?.before_checking_data?.ph_status}-text`}>{data?.before_checking_data?.ph_status}</span>
                            </div>}
                            <div className="line-div">
                                <p>Leak</p>
                                {data.before_checking_data?.leak
                                    ? <span className={`text-badge green-text`}>Yes</span>
                                    : <span className={`text-badge `}>No</span>}
                            </div>
                            <div className="line-div">
                                <p>Scratch and crack</p>
                                {data.before_checking_data?.scratch_crack
                                    ? <span className={`text-badge green-text`}>Yes</span>
                                    : <span className={`text-badge `}>No</span>}
                            </div>

                        </div>
                    </div>

                    {/* With new spun filter */}
                    {(data?.flow_checking_second_time?.reject_water_flow_status || data?.flow_checking_second_time?.filtered_water_flow_status) && <div className="sub-section-div">
                        <h5>With new spun filter</h5>
                        <div className='texts'>
                            {data.flow_checking_second_time?.tds_status && <div className="line-div">
                                <p>TDS</p>
                                <p>{data.flow_checking_second_time?.input_tds} / {data.flow_checking_second_time?.output_tds}</p>
                                <span className={`text-badge ${data.flow_checking_second_time?.tds_status}-text`}>{data.flow_checking_second_time?.tds_status}</span>
                            </div>}
                            {data?.flow_checking_second_time?.filtered_water_flow_status && <div className="line-div">
                                <p>Filter water flow</p>
                                <p>{data.flow_checking_second_time?.filtered_water_flow_ltrs}</p>
                                <span className={`text-badge ${data?.flow_checking_second_time?.filtered_water_flow_status}-text`}>{data?.flow_checking_second_time?.filtered_water_flow_status}</span>
                            </div>}
                            {data?.flow_checking_second_time?.reject_water_flow_status && <div className="line-div">
                                <p>Reject water flow</p>
                                <p>{data.flow_checking_second_time?.reject_water_flow_ltrs}</p>
                                <span className={`text-badge ${data?.flow_checking_second_time?.reject_water_flow_status}-text`}>{data?.flow_checking_second_time?.reject_water_flow_status}</span>
                            </div>}
                            {data?.flow_checking_second_time?.ph_status && <div className="line-div">
                                <p>PH</p>
                                <p>{data.flow_checking_second_time?.ph}</p>
                                <span className={`text-badge ${data?.flow_checking_second_time?.ph_status}-text`}>{data?.flow_checking_second_time?.ph_status}</span>
                            </div>}
                        </div>
                    </div>}

                    {/* Pump and membrane checking */}
                    {(data?.pump_membrane_checking?.length > 0 || data.membrane_tds_info?.tds_status) && <div className="sub-section-div">
                        <h5>Pump and membrane checking</h5>
                        <div className='texts'>
                            {data.membrane_tds_info?.tds_status && <div className="line-div">
                                <p>M-TDS</p>
                                <p>{data.membrane_tds_info?.input_tds} / {data.membrane_tds_info?.output_tds}</p>
                                <span className={`text-badge ${data.membrane_tds_info?.tds_status}-text`}>{data.membrane_tds_info?.tds_status}</span>
                            </div>}
                            <div className="line-div line-head" >
                                <p>OP OM TYPE</p>
                                <p>FILTER / STATUS</p>
                                <p>REJECT / STATUS</p>
                            </div>
                            {data?.pump_membrane_checking?.map((obj, index) => {
                                return obj?.type ?
                                    <div className="line-div" key={index}>
                                        <p>{obj?.type}</p>
                                        <p>{obj?.filter_water} / <span className={`text-badge ${obj?.filter_water_status}-text`}>{obj?.filter_water_status}</span></p>
                                        <p>{obj?.reject_water} / <span className={`text-badge ${obj?.reject_water_status}-text`}>{obj?.reject_water_status}</span></p>

                                    </div> : ''
                            })}
                        </div>
                    </div>}

                    {/* Inspection report */}
                    {data.inspection_report?.nature_of_complaint?.[0] && <div className="sub-section-div">
                        <h5>Inspection report</h5>
                        <div className='texts'>
                            <div className="line-div" style={{ borderBottom: '1px dashed gray' }}>
                                <p>Nature of complaint</p>
                                <p>{data.inspection_report?.nature_of_complaint?.map((value) => <>{value}<br></br></>)}</p>
                            </div>
                            <div className="line-div" style={{ borderBottom: '1px dashed gray' }}>
                                <p>Reason of complaint</p>
                                <p>{data.inspection_report?.reason_of_complaint?.map((value) => <>{value}<br></br></>)}</p>
                            </div>
                            <div className="line-div" >
                                <p>Solution of complaint</p>
                                <p>{data.inspection_report?.solution_of_complaint?.map((value) => <>{value}<br></br></>)}</p>
                            </div>
                        </div>
                    </div>}

                    {/* Evaluation */}
                    <div className="sub-section-div">
                        <h5>Evaluation</h5>
                        <div className='texts'>
                            <div className="line-div">
                                <p>TDS</p>
                                <p>{data.evaluation_data?.input_tds} / {data.evaluation_data?.output_tds}</p>
                                <span className={`text-badge ${data.evaluation_data?.tds_status}-text`}>{data.evaluation_data?.tds_status}</span>
                            </div>
                            {data?.evaluation_data?.filtered_water_flow_status && <div className="line-div">
                                <p>Filter water flow</p>
                                <p>{data.evaluation_data?.filtered_water_flow_ltrs} / {data.evaluation_data?.pump}</p>
                                <span className={`text-badge ${data?.evaluation_data?.filtered_water_flow_status}-text`}>{data?.evaluation_data?.filtered_water_flow_status}</span>
                            </div>}
                            {data?.evaluation_data?.reject_water_flow_status && <div className="line-div">
                                <p>Reject water flow</p>
                                <p>{data.evaluation_data?.reject_water_flow_ltrs} / FR {data.evaluation_data?.fr}</p>
                                <span className={`text-badge ${data?.evaluation_data?.reject_water_flow_status}-text`}>{data?.evaluation_data?.reject_water_flow_status}</span>
                            </div>}
                            {data?.evaluation_data?.ph_status && <div className="line-div">
                                <p>PH</p>
                                <p>{data.evaluation_data?.ph}</p>
                                <span className={`text-badge ${data?.evaluation_data?.ph_status}-text`}>{data?.evaluation_data?.ph_status}</span>
                            </div>}
                            <div className="line-div">
                                <p>Water quality</p>
                                <span className={data.evaluation_data?.water_quality === 'poor' ? `text-badge red-text` : `text-badge green-text`}>
                                    {data.evaluation_data?.water_quality}</span>
                            </div>
                            {data?.evaluation_data?.poor_reason && <div className="line-div">
                                <p>Poor reason</p>
                                <p>{data.evaluation_data?.poor_reason || 'Nill'}</p>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>

            {/* Spare Changes */}
            {(data?.primary_spares?.[0] || data?.secondary_spares?.[0] || data?.special_spares?.[0])
                && <div className="section-div spare-changes-div">
                    <div className="section-title-div">
                        <FaRegDotCircle />
                        <h4>Spare Changes</h4>
                    </div>
                    <div className="content-div">
                        {/*  */}
                        <div className="sub-section-div">
                            <div className='table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>#ID</th>
                                            <th>SPARE NAME</th>
                                            <th>TYPE</th>
                                            <th>MRP</th>
                                            <th>DISCOUNT</th>
                                            <th>RATE</th>
                                            <th>QTY</th>
                                            <th>AMOUNT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.primary_spares?.map((spare) => <tr >
                                            <td>{spare?.spare_id}</td>
                                            <td>{spare?.spare_name}</td>
                                            <td>{'Primary'}</td>
                                            <td>₹{spare?.mrp}</td>
                                            {<td>{spare?.discount_percentage ? `${spare?.discount_percentage}%` : '-'}</td>}
                                            <td>₹{spare?.rate} {spare?.warranty && '(W)'}</td>
                                            <td>{spare?.quantity}</td>
                                            <td>₹{spare?.rate * spare?.quantity}</td>
                                        </tr>)}
                                        {data?.secondary_spares?.map((spare) => <tr >
                                            <td>{spare?.spare_id}</td>
                                            <td>{spare?.spare_name}</td>
                                            <td>{'Secondary'}</td>
                                            <td>₹{spare?.mrp}</td>
                                            <td>{spare?.discount_percentage ? `${spare?.discount_percentage}%` : '-'}</td>
                                            <td>₹{spare?.rate} {spare?.warranty && '(W)'}</td>
                                            <td>{spare?.quantity}</td>
                                            <td>₹{spare?.rate * spare?.quantity}</td>
                                        </tr>)}
                                        {data?.special_spares?.map((spare) => <tr >
                                            <td>{spare?.spare_id}</td>
                                            <td>{spare?.spare_name}</td>
                                            <td>{'Special'}</td>
                                            <td>₹{spare?.mrp}</td>
                                            <td>{spare?.discount_percentage ? `${spare?.discount_percentage}%` : '-'}</td>
                                            <td>₹{spare?.rate} {spare?.warranty && '(W)'}</td>
                                            <td>{spare?.quantity}</td>
                                            <td>₹{spare?.rate * spare?.quantity}</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {/* Bill and Payment */}
            <div className='bill-feed-main-div'>
                {/* Bill and Payment */}
                <div className="section-div bill-div">
                    <div className="section-title-div">
                        <FaRegDotCircle />
                        <h4>Bill and Payment</h4>
                    </div>
                    <div className="content-div">
                        {/* Bill */}
                        <div className="sub-section-div">
                            <h5>Bill</h5>
                            <div className='table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Est.</th>
                                            <th>Receive</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.bill_data?.amc_fund && <tr>
                                            <td>AMC Fund</td>
                                            <td></td>
                                            <td>₹{data?.bill_data?.amc_fund}</td>
                                        </tr>}
                                        {data?.bill_data?.ssp_fund && <tr>
                                            <td>SSP Fund</td>
                                            <td></td>
                                            <td>₹{data?.bill_data?.ssp_fund}</td>
                                        </tr>}
                                        <tr>
                                            <td>Primary spares</td>
                                            <td>₹{data?.bill_data?.primary_spare_total_est || 0}</td>
                                            <td>₹{data?.bill_data?.primary_spare_total_receivable || 0}</td>
                                        </tr>
                                        <tr>
                                            <td>Secondary spares</td>
                                            <td>₹{data?.bill_data?.secondary_spare_total_est || 0}</td>
                                            <td>₹{data?.bill_data?.secondary_spare_total_receivable || 0}</td>
                                        </tr>
                                        <tr>
                                            <td>Special work spares</td>
                                            <td>₹{data?.bill_data?.special_work_spare_total_est || 0}</td>
                                            <td>₹{data?.bill_data?.special_work_spare_total_receivable || 0}</td>
                                        </tr>
                                        <tr>
                                            <td>Service charge</td>
                                            <td>₹{data?.bill_data?.service_charge_est || 0}</td>
                                            <td>₹{data?.bill_data?.service_charge_receivable || 0}</td>
                                        </tr>
                                        {data?.bill_data?.extra_charge_est ? <tr>
                                            <td>Extra charge</td>
                                            <td>₹{data?.bill_data?.extra_charge_est || 0}</td>
                                            <td>₹{data?.bill_data?.extra_charge_receivable || 0}</td>
                                        </tr> : ""}
                                        {data?.bill_data?.compliment_given_now ? <>
                                            <tr className='total'>
                                                <td>Total</td>
                                                <td>₹{data?.bill_data?.total_est}</td>
                                                <td>₹{data?.bill_data?.total_receivable}</td>
                                            </tr>
                                            <tr>
                                                <td>Compliment</td>
                                                <td>₹{data?.bill_data?.compliment_given_now}</td>
                                                <td>₹{data?.bill_data?.compliment_given_now}</td>
                                            </tr>
                                        </> : ''}
                                        <tr className='grand-total'>
                                            <td>Grand Total</td>
                                            <td>₹{data?.bill_data?.grand_total_est}</td>
                                            <td>₹{data?.bill_data?.grand_total_receivable}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/* Payment */}
                        <div className="sub-section-div">
                            <h5>Payment</h5>
                            <div className='table'>
                                {data?.payment_history?.[0] ?
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                {/* <th>PayId</th> */}
                                                <th>Method</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.payment_history?.map((pay) => <tr>
                                                <td>{pay.received_date}</td>
                                                {/* <td>{pay.pay_id}</td> */}
                                                <td>{pay.received_method}</td>
                                                <td>{pay.total_amount}
                                                    {pay?.confirm && <span title='Confirmed' style={{ color: 'green', marginLeft: '5px' }}><FaCheckCircle /></span>}
                                                    {pay?.rejected && <span title='Rejected' style={{ color: 'red', marginLeft: '5px' }}><IoMdCloseCircle /></span>}</td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                    : <Spinner height={'100px'} message='No Cash deals' icon={<LuIndianRupee />} spin={false} />}
                            </div>
                        </div>

                    </div>
                </div>
                {/* Tech Feedback */}
                <div className="section-div feedback-div">
                    <div className="section-title-div">
                        <FaRegDotCircle />
                        <h4>Tech Feedback</h4>
                    </div>
                    <div className="content-div">
                        <div className="sub-section-div">
                            <div className='table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Type</th>
                                            <th>Star</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td>Information accuracy </td>
                                            <td><FaStar /> {data?.tech_feedback?.inaccurate_info || 0}</td>
                                        </tr>
                                        <tr>
                                            <td>delaying the work</td>
                                            <td><FaStar /> {data?.tech_feedback?.delaying_the_work || 0}</td>
                                        </tr>
                                        <tr>
                                            <td>disruptions in workflow</td>
                                            <td><FaStar /> {data?.tech_feedback?.disruptions_in_workflow || 0}</td>
                                        </tr>
                                        <tr style={{ color: 'red' }}>
                                            <td>star in 10</td>
                                            <td><FaStar /> {data?.tech_feedback?.star_in_ten || 0}</td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceFullData