import React, { useState } from 'react'
import './style.scss'
import './section-six.scss'
import { GiWaterRecycling } from "react-icons/gi";
import { RiWaterFlashFill, RiWaterPercentFill, RiChatNewFill } from "react-icons/ri";
import { TbDropletCog, TbDropletHalf2Filled } from "react-icons/tb";
import { useDispatch, useSelector } from 'react-redux';
import { workTypeFinder } from '../../../assets/javascript/find-functions'
import { changeFormValue } from '../../../redux/features/user/serviceSlice'
import { TbAdjustmentsCheck } from "react-icons/tb";
import WorkCategory from '../service-form-sub-comp/WorkCategory';
import SpareList from '../service-form-sub-comp/SpareList';


const SectionSix = React.memo(({ purifier, showInput, eligibility, reg_type, customer, product, resources, packageDetails, allSpares }) => {

    const dispatch = useDispatch();
    const { serviceForm } = useSelector((state) => state.serviceData)
    const [spareType, setSpareType] = useState('')

    const commonData = {
        package_id: purifier?.package_id || undefined,
        current_ssp_card_no: customer?.purifier_ssp_card_number || undefined,
        purifier_customer_status: customer?.purifier_customer_status,
        service_data: {
            repeat_software: eligibility?.repeat,
            repeat_tech: eligibility?.repeat,
            previous_work_tech: eligibility?.previous_work_tech || undefined,
            previous_work_srl_number: eligibility?.previous_work_srl_number || undefined,
        }
    }

    const selectPeriodical = () => {
        const EstServiceChange = packageDetails?.periodical?.service_charge_est?.[0]
        const serviceCharge = packageDetails?.periodical?.service_charge_receivable?.[0]
        const initialCall = packageDetails?.periodical?.call?.[0]

        dispatch(changeFormValue({
            ...serviceForm,
            ...commonData,
            work_category_selected: 'periodical',
            work_method: 'periodical service',
            work_type: workTypeFinder(customer?.purifier_customer_status, null, 'periodical_service'),
            work: reg_type === 'complaint' ? 'COMPLAINT, SERVICE' : 'SERVICE',
            complaint_index: (purifier?.complaint_count?.length || 0),
            service_index: (purifier?.service_count?.length || 0) + 1,
            service_data: {
                ...serviceForm?.service_data || {},
                ...commonData?.service_data || {},
                periodical_service: true
            },
            bill_data: {
                ...serviceForm?.bill_data || {},
                service_charge_est: EstServiceChange,
                service_charge_tally: serviceCharge,
                service_charge_receivable: serviceCharge
            },
            call_details: {
                primary_secondary: initialCall || 0
            }
        }))
    }

    const selectAmc = () => {
        const EstServiceChange = packageDetails?.amc?.service_charge_est?.[0]
        const serviceCharge = packageDetails?.amc?.service_charge_receivable?.[0]
        const initialCall = packageDetails?.amc?.call?.[0]

        dispatch(changeFormValue({
            ...serviceForm,
            ...commonData,
            work_category_selected: 'amc',
            work_method: 'amc package renewal',
            work_type: workTypeFinder(customer?.purifier_customer_status, 'AMC', 'package_renewal'),
            work: reg_type === 'complaint' ? 'COMPLAINT, RENEWAL' : 'RENEWAL',
            complaint_index: (purifier?.complaint_count?.length || 0),
            service_index: (purifier?.service_count?.length || 0) + 1,
            service_data: {
                ...serviceForm?.service_data || {},
                ...commonData?.service_data || {},
                carbon_filter_changed: true,
                package_renewal: true,
                package_name: 'amc',
                package_id: null,
                new_customer_status: 'AMC'
            },
            bill_data: {
                ...serviceForm?.bill_data || {},
                amc_fund: (product?.amc_fund || 0),
                service_charge_est: EstServiceChange,
                service_charge_tally: serviceCharge,
                service_charge_receivable: serviceCharge
            },
            call_details: {
                primary_secondary: initialCall || 0
            }
        }))
    }

    const selectSsp = () => {
        const EstServiceChange = packageDetails?.ssp?.service_charge_est?.[0]
        const serviceCharge = packageDetails?.ssp?.service_charge_receivable?.[0]
        const initialCall = packageDetails?.ssp?.call?.[0]

        dispatch(changeFormValue({
            ...serviceForm,
            ...commonData,
            work_category_selected: 'ssp',
            work_method: 'ssp package renewal',
            work_type: workTypeFinder(customer?.purifier_customer_status, 'SSP', 'package_renewal'),
            work: reg_type === 'complaint' ? 'COMPLAINT, RENEWAL' : 'RENEWAL',
            complaint_index: (purifier?.complaint_count?.length || 0),
            service_index: (purifier?.service_count?.length || 0) + 1,
            service_data: {
                ...serviceForm?.service_data || {},
                ...commonData?.service_data || {},
                carbon_filter_changed: true,
                package_renewal: true,
                package_name: 'ssp',
                package_id: null,
                new_customer_status: 'SSP'
            },
            bill_data: {
                ...serviceForm?.bill_data || {},
                ssp_fund: (product?.ssp_fund || 0),
                service_charge_est: EstServiceChange,
                service_charge_tally: serviceCharge,
                service_charge_receivable: serviceCharge
            },
            call_details: {
                primary_secondary: initialCall || 0
            }
        }))
    }

    const selectCarbon = () => {
        const EstServiceChange = packageDetails?.carbon?.service_charge_est?.[0]
        const serviceCharge = packageDetails?.carbon?.service_charge_receivable?.[0]
        const initialCall = packageDetails?.carbon?.call?.[0]

        dispatch(changeFormValue({
            ...serviceForm,
            ...commonData,
            work_category_selected: 'carbon',
            work_method: 'carbon change',
            work_type: workTypeFinder(customer?.purifier_customer_status, null, 'carbon_change_without_package'),
            work: reg_type === 'complaint' ? 'COMPLAINT, SERVICE' : 'SERVICE',
            complaint_index: (purifier?.complaint_count?.length || 0),
            service_index: (purifier?.service_count?.length || 0) + 1,
            service_data: {
                ...serviceForm?.service_data || {},
                ...commonData?.service_data || {},
                carbon_filter_changed: true,
                package_renewal: false,
                new_customer_status: 'O/W'
            },
            bill_data: {
                ...serviceForm?.bill_data || {},
                service_charge_est: EstServiceChange,
                service_charge_tally: serviceCharge,
                service_charge_receivable: serviceCharge
            },
            call_details: {
                primary_secondary: initialCall || 0
            }
        }))
    }

    const selectComplaint = () => {
        const EstServiceChange = packageDetails?.complaint?.service_charge_est?.[0]
        const serviceCharge = packageDetails?.complaint?.service_charge_receivable?.[0]
        const initialCall = packageDetails?.complaint?.call?.[0]

        dispatch(changeFormValue({
            ...serviceForm,
            ...commonData,
            work_category_selected: 'complaint',
            work_method: 'complaint',
            work_type: workTypeFinder(customer?.purifier_customer_status, null, 'complaint'),
            work: 'COMPLAINT',
            complaint_index: (purifier?.complaint_count?.length || 0) + 1,
            service_index: (purifier?.service_count?.length || 0),
            bill_data: {
                ...serviceForm?.bill_data || {},
                service_charge_est: EstServiceChange,
                service_charge_tally: serviceCharge,
                service_charge_receivable: serviceCharge
            },
            call_details: {
                primary_secondary: initialCall || 0
            }
        }))
    }

    const selectMachineCheckup = () => {
        const EstServiceChange = packageDetails?.checkup?.service_charge_est?.[0]
        const serviceCharge = packageDetails?.checkup?.service_charge_receivable?.[0]
        const initialCall = packageDetails?.checkup?.call?.[0]

        dispatch(changeFormValue({
            ...serviceForm,
            work_category_selected: 'checkup',
            work_method: 'machine checkup',
            work_type: workTypeFinder(customer?.purifier_customer_status, null, 'machine_checkup'),
            work: 'CHECKUP',
            complaint_index: (purifier?.complaint_count?.length || 0) + 1,
            service_index: (purifier?.service_count?.length || 0),
            purifier_customer_status: customer?.purifier_customer_status,
            service_data: {
                ...serviceForm?.service_data || {},
                ...commonData?.service_data || {},
                repeat_software: eligibility?.repeat
            },
            bill_data: {
                ...serviceForm?.bill_data || {},
                service_charge_est: EstServiceChange,
                service_charge_tally: serviceCharge,
                service_charge_receivable: serviceCharge
            },
            call_details: {
                primary_secondary: initialCall || 0
            }
        }))
    }

    const selectRequireNewMachine = () => {
        const EstServiceChange = packageDetails?.machine_exchange?.service_charge_est?.[0]
        const serviceCharge = packageDetails?.machine_exchange?.service_charge_receivable?.[0]
        const initialCall = packageDetails?.machine_exchange?.call?.[0]

        dispatch(changeFormValue({
            ...serviceForm,
            work_category_selected: 'machine_exchange',
            work_method: 'require new machine',
            work_type: workTypeFinder(customer?.purifier_customer_status, null, 'require_new_machine'),
            work: 'REQUIRED',
            complaint_index: (purifier?.complaint_count?.length || 0) + 1,
            service_index: (purifier?.service_count?.length || 0),
            purifier_customer_status: customer?.purifier_customer_status,
            service_data: {
                ...serviceForm?.service_data || {},
                ...commonData?.service_data || {},
                repeat_software: eligibility?.repeat
            },
            bill_data: {
                ...serviceForm?.bill_data || {},
                service_charge_est: EstServiceChange,
                service_charge_tally: serviceCharge,
                service_charge_receivable: serviceCharge
            },
            call_details: {
                primary_secondary: initialCall || 0
            }
        }))
    }


    return (
        <div className="service-form-section-div">
            <div className="title-section">
                <h3>Work category</h3>
                <p>{serviceForm?.work_category_selected
                    ? (serviceForm?.work_type)?.toUpperCase()
                    : 'Select any work category and choose spares'}</p>
            </div>
            {/* Package List */}
            {!serviceForm?.work_category_selected &&
                <div className="work-category-list">
                    {eligibility?.service && packageDetails?.periodical &&
                        <div className="item-div" onClick={selectPeriodical}>
                            <div className="icon-div">
                                <GiWaterRecycling />
                            </div>
                            <h5>Periodical service</h5>
                        </div>}
                    {eligibility?.renewal && eligibility?.amc && packageDetails?.amc &&
                        <div className="item-div" onClick={selectAmc} >
                            <div className="icon-div">
                                <RiWaterFlashFill />
                            </div>
                            <h5>AMC Renewal</h5>
                        </div>}
                    {eligibility?.renewal && packageDetails?.ssp &&
                        <div className="item-div" onClick={selectSsp}>
                            <div className="icon-div" >
                                <RiWaterPercentFill />
                            </div>
                            <h5>SSP Renewal</h5>
                        </div>}
                    {reg_type === 'complaint' && packageDetails?.complaint &&
                        <div className="item-div" onClick={selectComplaint}>
                            <div className="icon-div">
                                <TbDropletCog />
                            </div>
                            <h5>Complaint only</h5>
                        </div>}
                    {eligibility?.renewal && packageDetails?.carbon &&
                        <div className="item-div" onClick={selectCarbon}>
                            <div className="icon-div">
                                <TbDropletHalf2Filled />
                            </div>
                            <h5>Carbon change <br></br> (With out page)</h5>
                        </div>}
                    {packageDetails?.checkup &&
                        <div className="item-div" onClick={selectMachineCheckup}>
                            <div className="icon-div">
                                <TbAdjustmentsCheck />
                            </div>
                            <h5>Machine checkup</h5>
                        </div>}
                    {packageDetails?.machine_exchange &&
                        <div className="item-div" onClick={selectRequireNewMachine}>
                            <div className="icon-div">
                                <RiChatNewFill />
                            </div>
                            <h5>Require new Machine</h5>
                        </div>
                    }
                </div>}


            {/* Spare List */}
            {spareType && <div className="spare-list-main-div">
                <SpareList customerSpare={purifier?.spare_list?.filter((spare) => spare?.type === spareType) || []}
                    allSpare={allSpares?.[spareType]} spareType={spareType} aboutWork={packageDetails[serviceForm?.work_category_selected]}
                    setSpareType={setSpareType} product={product}
                />
            </div>}


            {/* Package View */}
            {!spareType && serviceForm?.work_category_selected && <div className="spare-list-main-div">
                <WorkCategory purifier={purifier} showInput={showInput} eligibility={eligibility}
                    reg_type={reg_type} customer={customer} product={product} resources={resources}
                    setSpareType={setSpareType} aboutWork={packageDetails[serviceForm?.work_category_selected]} />
            </div>}


        </div >
    )
})

export default SectionSix