import axios from 'axios'
export const baseIP = 'localhost'

const baseSetup = {
    authAxios: axios.create({
        baseURL: `https://purifierservice.api.alliancedev.in/auth/`,
        headers: {
            'Content-Type': 'application/json'
        }
    }),
    userAxios: axios.create({
        baseURL: `https://purifierservice.api.alliancedev.in/`,
        headers: {
            'Content-Type': 'application/json'
        }
    }),
    serviceAxios: axios.create({
        baseURL: `https://purifierservice.api.alliancedev.in/service/`,
        headers: {
            'Content-Type': 'application/json'
        }
    }),
    servicedAxios: axios.create({
        baseURL: `https://purifierservice.api.alliancedev.in/serviced/`,
        headers: {
            'Content-Type': 'application/json'
        }
    }),
    customerAxios: axios.create({
        baseURL: `https://controlnex.api.alliancedev.in/`,
        headers: {
            'Content-Type': 'application/json'
        }
    }),
    adminAxios: axios.create({
        baseURL: `https://purifierservice.api.alliancedev.in/admin/`,
        headers: {
            'Content-Type': 'application/json'
        }
    }),
    staffAxios: axios.create({
        baseURL: `https://staff.api.alliancedev.in/`,
        headers: {
            'Content-Type': 'application/json'
        }
    })

}


const handleTokenError = () => {
    // Redirect the user to the login page or perform any other necessary action
    window.location.href = `http://staff.alliancedev.in`
}

const requestConfigFunction = (config) => {
    let userToken = localStorage.getItem('_tkn_stf')
    if (userToken) {
        config.headers['Authorization'] = `Bearer ${userToken}`;
    }
    return config
}

const requestErrorFunction = (error) => {
    return Promise.reject(error);
}

const responseConfigFunction = (response) => {
    // Handle successful responses here if needed
    return response.data;
}

const responseErrorFunction = (error) => {
    if (error.response && error.response.status === 401) {
        handleTokenError();
    }
    return Promise.reject(error.response.data);
}


// Add an interceptor to authAxios for request
baseSetup.authAxios.interceptors.request.use(
    requestConfigFunction, requestErrorFunction
)

// Add an interceptor to authAxios for response errors
baseSetup.authAxios.interceptors.response.use(
    responseConfigFunction, responseErrorFunction
);

// Add an interceptor to userAxios for request
baseSetup.userAxios.interceptors.request.use(
    requestConfigFunction, requestErrorFunction
)

// Add an interceptor to userAxios for response errors
baseSetup.userAxios.interceptors.response.use(
    responseConfigFunction, responseErrorFunction
);

// Add an interceptor to serviceAxios for request
baseSetup.serviceAxios.interceptors.request.use(
    requestConfigFunction, requestErrorFunction
)

// Add an interceptor to serviceAxios for response errors
baseSetup.serviceAxios.interceptors.response.use(
    responseConfigFunction, responseErrorFunction
);

// Add an interceptor to servicedAxios for request
baseSetup.servicedAxios.interceptors.request.use(
    requestConfigFunction, requestErrorFunction
)

// Add an interceptor to servicedAxios for response errors
baseSetup.servicedAxios.interceptors.response.use(
    responseConfigFunction, responseErrorFunction
);

// Add an interceptor to customerAxios for request
baseSetup.customerAxios.interceptors.request.use(
    requestConfigFunction, requestErrorFunction
)

// Add an interceptor to customerAxios for response errors
baseSetup.customerAxios.interceptors.response.use(
    responseConfigFunction, responseErrorFunction
);

// Add an interceptor to adminAxios for request
baseSetup.adminAxios.interceptors.request.use(
    requestConfigFunction, requestErrorFunction
)

// Add an interceptor to adminAxios for response errors
baseSetup.adminAxios.interceptors.response.use(
    responseConfigFunction, responseErrorFunction
);
// Add an interceptor to staffAxios for request
baseSetup.staffAxios.interceptors.request.use(
    requestConfigFunction, requestErrorFunction
)

// Add an interceptor to staffAxios for response errors
baseSetup.staffAxios.interceptors.response.use(
    responseConfigFunction, responseErrorFunction
);


export const { authAxios, userAxios, serviceAxios, servicedAxios, customerAxios, adminAxios, staffAxios } = baseSetup


