import React, { useEffect } from 'react'
import { Routes, Route, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { baseIP } from '../config/axios'
import { loginAdmin } from '../redux/features/admin/adminAuthSlice'
import NotFound from '../pages/admin/404/NotFound'
import Home from '../pages/admin/home/Home'
import ZoneList from '../pages/admin/zone/ZoneList'
import InputOptionPage from '../pages/admin/input-option-page/InputOptionPage'
import InputSingleOption from '../pages/admin/input-single-option/InputSingleOption'
import SubOptionInput from '../pages/admin/with-sub-option-resource/SubOptionInput'
import ServiceComplaintRegList from '../pages/admin/reg-list/ServiceComplaintRegList'
import CustomerList from '../pages/admin/customer-list/CustomerList'
import ViewEditCustomer from '../pages/admin/view-edit-customer/ViewEditCustomer'
import InputSingleOptionStatus from '../pages/admin/input-single-option-status/InputSingleOptionStatus'
import CustomerDetails from '../pages/admin/customer-details/CustomerDetails'
import ServicedList from '../pages/admin/serviced-list/ServicedList'
import ServicedData from '../pages/admin/serviced-data/ServicedData'
import RedYellowList from '../pages/admin/red-yellow-list/RedYellowList'
import UpcomingPackages from '../pages/admin/upcoming-packages/UpcomingPackages'
import BlackList from '../pages/admin/red-yellow-list/BlackList'

const Admin = () => {
    const { admin } = useSelector((state) => state.adminAuth)
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch()
    let isAuthenticated = false

    if (admin?.token) {
        isAuthenticated = true
    }

    useEffect(() => {
        const id = searchParams.get('id');
        if (!id && !admin?._id) {
            window.location.href = `http://staff.alliancedev.in`
        } else if (id || admin?._id) {
            dispatch(loginAdmin(id || admin?._id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Routes>
            {/* Home */}
            <Route path='/' element={<PrivateRoute element={<Home />} isAuthenticated={isAuthenticated} />} />
            {/* Zone */}
            <Route path='/zone-list' element={<PrivateRoute element={<ZoneList />} isAuthenticated={isAuthenticated} />} />

            {/* Input Options */}
            <Route path='/input-resources' element={<PrivateRoute element={<InputOptionPage />} isAuthenticated={isAuthenticated} />} />
            <Route path='/input-resources/single/:title' element={<PrivateRoute element={<InputSingleOption />} isAuthenticated={isAuthenticated} />} />
            <Route path='/input-resources/sub_option/:title' element={<PrivateRoute element={<SubOptionInput />} isAuthenticated={isAuthenticated} />} />
            <Route path='/input-resources/single-with-status/:title' element={<PrivateRoute element={<InputSingleOptionStatus />} isAuthenticated={isAuthenticated} />} />

            {/* Registrations */}
            <Route path='/complaints-list' element={<PrivateRoute element={<ServiceComplaintRegList />} isAuthenticated={isAuthenticated} />} />
            <Route path='/service-list' element={<PrivateRoute element={<ServiceComplaintRegList />} isAuthenticated={isAuthenticated} />} />
            <Route path='/service/yellow-list' element={<PrivateRoute element={<RedYellowList listType={'yellow'} />} isAuthenticated={isAuthenticated} />} />
            <Route path='/service/red-list' element={<PrivateRoute element={<RedYellowList listType={'red'} />} isAuthenticated={isAuthenticated} />} />
            <Route path='/service/black-list' element={<PrivateRoute element={<BlackList />} isAuthenticated={isAuthenticated} />} />

            {/* Customer */}
            <Route path='/customer-list' element={<PrivateRoute element={<CustomerList />} isAuthenticated={isAuthenticated} />} />
            <Route path='/customer-list/:cid/view' element={<PrivateRoute element={<ViewEditCustomer />} isAuthenticated={isAuthenticated} />} />
            <Route path='/customer-list/:cid/cards' element={<PrivateRoute element={<CustomerDetails />} isAuthenticated={isAuthenticated} />} />

            {/* Serviced */}
            <Route path='/serviced-list' element={<PrivateRoute element={<ServicedList />} isAuthenticated={isAuthenticated} />} />
            <Route path='/serviced-list/:srl_number/view' element={<PrivateRoute element={<ServicedData />} isAuthenticated={isAuthenticated} />} />

            {/* Package */}
            <Route path='/upcoming-package-list' element={<PrivateRoute element={<UpcomingPackages />} isAuthenticated={isAuthenticated} />} />

            {/* 404 Route */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    )
}

function PrivateRoute({ element, isAuthenticated }) {
    return isAuthenticated && (
        <Routes>
            <Route path='/' element={element} />
        </Routes>
    )

}

export default Admin