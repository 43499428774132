import React from 'react'
import './settings.scss'
import SinglePage from '../../../components/common/page/SinglePage'
import { IoWarning } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { setField, logOut } from '../../../redux/features/user/userAuthSlice'
import { clearFormData } from '../../../redux/features/user/serviceSlice'


const Settings = () => {

    const { user } = useSelector((state) => state.userAuth)
    const { internet } = useSelector((state) => state.network)

    const dispatch = useDispatch()



    const handleZoneChange = (e) => {
        // let ask = window.confirm('Are your change the zone ?')
        // if (ask) {
        dispatch(setField({ active_zone: user.zone_list.filter((zone) => zone.zone === e.target.value)[0] }))
        dispatch(clearFormData())
        // }
    }

    // eslint-disable-next-line
    const handleLogOut = () => {
        dispatch(logOut())
        window.location.href = 'http://staff.alliancedev.in'
    }




    return (
        <div className='settings-div'>
            <SinglePage titleArray={['Settings']}>
                {/* Network */}
                <div className="section-div">
                    <div className="section-title">
                        <h4>Network</h4>
                    </div>
                    <div className="section-content-div">
                        <div className="option-div">
                            <div className="left">
                                <p className='title'>Network status</p>
                            </div>
                            <div className="right">
                                {internet ? <span className='text-badge green-text'>Online</span>
                                    : <span className='text-badge offline-text'>Offline</span>}
                            </div>
                        </div>
                        <div className="option-div">
                            <div className="left">
                                <p className='title'>Sync data</p>
                            </div>
                            <div className="right">
                                <select name="" id="">
                                    <option value="">Auto</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Service */}
                <div className="section-div">
                    <div className="section-title">
                        <h4>Service</h4>
                    </div>
                    <div className="section-content-div">
                        <div className="option-div">
                            <div className="left">
                                <p className='title'>Change zone</p>
                                <small className='warning'><IoWarning /> When changing the zone, the local storage will be cleared</small>
                            </div>
                            <div className="right">
                                <select name="zone" id="" onChange={handleZoneChange}>
                                    {user?.zone_list?.map((zone) => {
                                        return <option selected={zone.zone === user?.active_zone?.zone} value={zone.zone}>{zone.zone}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>

                </div>


                {/* Quit */}
                {/* <div className="section-div">
                    <div className="section-title">

                    </div>
                    <div className="section-content-div">
                        <div className="option-div">
                            <div className="left">
                                <p className='title'>Exit from site</p>
                            </div>
                            <div className="right">
                                <button onClick={handleLogOut} className='exit'><RiLogoutCircleLine /> Exit</button>
                            </div>
                        </div>
                    </div>
                </div> */}
            </SinglePage>
        </div>
    )
}

export default Settings