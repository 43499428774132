import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { authAxios, baseIP } from '../../../config/axios'

const initialState = {
    admin: null,
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: '' 
}

export const loginAdmin = createAsyncThunk('admin/login', async (id, thunkAPI) => {

    try {
        return await authAxios.post(`/admin/login/?userId=${id}`)
    } catch (error) {
        const message = error || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const adminAuthSlice = createSlice({
    name: 'adminAuth',
    initialState,
    reducers: {
        reset: (state) => {
            state.isError = false
            state.isLoading = false
            state.isSuccess = false
            state.message = ''
        },
        // setAdmin: (state, action) => {
        //     state.admin = action.payload
        // },
        logOut: (state) => {
            localStorage.removeItem('_tkn_stf');
            state.admin = null
        },
        // setField: (state, action) => {
        //     state.admin = { ...state.admin, ...action.payload }
        // }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginAdmin.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(loginAdmin.fulfilled, (state, action) => {
                localStorage.setItem(
                    '_tkn_stf', action.payload.data.token
                );
                state.isLoading = false;
                state.admin = action.payload.data;
            })
            .addCase(loginAdmin.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                window.location.href = `http://staff.alliancedev.in`
            });
    },

})


export const { reset, logOut, setUser, setField } = adminAuthSlice.actions;
export default adminAuthSlice.reducer